






































import {Component, Vue} from 'vue-property-decorator';
import PhoneInput from "@/components/util/PhoneInput.vue";
import AutoComplete from "@/components/common/AutoComplete.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import {InputType} from "@/components/common/InputType";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import W9CRContractorDTO from "@/dto/request/w9cr/W9CRContractorDTO";
import {FormFiller, W9CRContractorStatus} from "@/constants/request/W9CRConstants";
import W9CRCompanyData from "@/components/request/w9cr/W9CRCompanyData.vue";
import W9CRService from "@/services/request/W9CRService";
import {namespace} from "vuex-class";

const AppModule = namespace("App");

@Component({
  computed: {
    FormFiller() {
      return FormFiller
    },
    W9CRContractorStatus() {
      return W9CRContractorStatus
    },
    InputType() {
      return InputType
    },
    AutoCompleteDTO() {
      return AutoCompleteDTO
    }
  },
  components: {W9CRCompanyData, SingleFileHolder, PortalInput, AutoComplete, PhoneInput}
})
export default class CompanyW9CR extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  private contractor: W9CRContractorDTO | null = null;
  private emailProvided = false;

  private customerHasForm: boolean | null = null;

  mounted(){
    this.loadRequest();
  }

  loadRequest(){
    this.startLoading();
    W9CRService.getPublicContractor(this.id).then(
        ok => {
          this.contractor = ok.data;
          this.emailProvided = !!ok.data.email;
          this.stopLoading();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    )
  }

  get id(){
    return this.$route.params.id;
  }

  w9Uploaded() {
    if(this.contractor){
      this.contractor.status = W9CRContractorStatus.DOCUMENTS_PROVIDED;
      this.startLoading();
          W9CRService.saveContractorByContractor(this.$route.params.id, this.contractor).then(
          ok => {
            this.stopLoading();
            console.log("OK")
          },
          err => {
            console.log(JSON.stringify(err))
            if(this.contractor){
              this.contractor.status = W9CRContractorStatus.FILLING_BY_CONTRACTOR;
            }
            this.stopLoading();
          }
      )
    }
  }

}
